@import "./map.scss";
@import "./header.scss";
@import "./variables.scss";

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html {
  font-size: $font-size-desktop-percentage;

  @include until("desktop") {
    font-size: $font-size-mobile-percentage;
  }
}

@mixin project-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1.5rem;
  row-gap: 4rem;

  a {
    display: block;
  }

  @include until("desktop") {
    grid-template-columns: repeat(2, 1fr);
  }

  .project-index-card {
    gap: 1.5rem;
    width: 100%;
    aspect-ratio: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    // background-color: #fbfbfb;
    position: relative;
    overflow: hidden;

    &:hover {
      img {
        // opacity: 0.9;
        transform: scale(1.005);
      }
    }

    &::after {
      content: "";
      position: absolute;
      inset: 0;
      background-color: rgba(0, 0, 0, 0.05);

      // z-index: 1;
    }

    img {
      object-fit: cover;
      height: 100%;
      width: 100%;
      transition: 0.2s all;
    }

    &:hover {
      background-color: rgba($grey, 0.1);
      transition: 0.1s all;
    }
  }

  .project-card-footer {
    margin-top: 0.75rem;
    word-break: break-word;

    .project-name {
      font-size: $text_normal;
      font-weight: bold;
      margin-bottom: 0;
    }

    .project-subtitle {
      font-size: $text_normal;
    }
  }
}

@font-face {
  font-family: "Hermes";
  src: url("/font/Hermes Bold.otf") format("opentype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Hermes";
  src: url("/font/Hermes Regular.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Hermes";
  src: url("/font/Hermes Light.otf") format("opentype");
  font-weight: 300;
  /* or 'light' */
  font-style: normal;
  font-display: swap;
}

body {
  max-width: 100vw;
  overflow-x: hidden;
  font-family: "Hermes", sans-serif !important;
  line-height: 1.4;
  font-size: $text_normal;
  color: $black;
  display: flex;
  flex-direction: column;
}

h1 {
  line-height: 1.2;
  font-size: $text_big;
}

h2 {
  font-size: $text_medium;
}

a {
  color: inherit;
  text-decoration: none;
}

.project-section {
  position: relative;
  width: 100%;
  height: auto;
}

.projects-by-year {
  scroll-behavior: smooth;
  scroll-snap-type: y mandatory;

  .project-index-container {
    scroll-snap-align: start;
    scroll-snap-stop: always;
    border-bottom: 1px black solid;

    &:last-of-type {
      border-bottom: none;
    }

    .year {
      font-weight: normal;
      font-size: $text_medium;
      margin-bottom: 1.5rem;
    }

    .project-index {
      @include project-grid;
    }
  }
}

.world-view {
  flex: 1;
  display: flex;
  flex-direction: column;

  .world-container {
    min-height: 80vh;
  }
}

.page:not(.home) {
  padding-top: 8rem;
  min-height: 95vh;

  @include mobile-only {
    padding-top: 2rem;
  }
}

.container {
  width: 100%;
  padding: 1.5rem;
  margin: 0 auto;

  &.padding-inline {
    padding-inline: 1.5rem;
    padding-block: 0;
  }

  .padding-bottom {
    padding-bottom: 1.5rem;
  }

  &.no-padding {
    padding-block: 0;
  }
}

.page-top {
  border-bottom: 1px $black solid;
}

.project-header-container {
  display: flex;
  align-items: flex-end;
  min-height: 180px;
  gap: 1.5rem;

  @include mobile-only {
    display: flex;
    flex-direction: column-reverse;
    min-height: unset;
    // align-items: center;

    // display: flex;
    // align-items: flex-end;
    // min-height: 180px;
    // gap: 1.5rem;
  }

  @include from("tablet") {
    & > * {
      flex: 1;
    }
  }
}

// .project-header-container-mobile {
//   display: none;

// }

.projects-nav {
  display: flex;
  gap: 1.5rem;
  margin: 1.5rem 0;
  font-weight: 400;

  @include mobile-only {
    width: 100%;
    margin-top: 0;
  }

  a {
    @include link;

    &.selected {
      opacity: 1;
    }
  }
}

.project-filter-container {
  @include mobile-only {
    display: none !important;
  }

  font-size: $text_normal;

  h3 {
    font-size: $text_normal;
  }

  .with-filter {
    h3 {
      font-size: $text_medium;
    }

    a {
      font-size: $text_normal;
    }
  }

  p {
    margin: 0.75rem 0;
    font-size: $text_medium;
    // font-weight: normal;
  }

  &.two-cols {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  margin-bottom: 1.5rem;

  a {
    @include link;

    &.selected {
      opacity: 1;
      font-weight: bolder;
    }
  }
}

.project-filter-container-mobile {
  width: 100%;

  h3 {
    font-size: $text_medium !important;
  }

  p {
    font-size: $text_medium !important;
    margin: 0.75rem 0;
    font-weight: normal;
  }

  a {
    @include link;
    margin-top: 1.5rem;
    display: block;

    &.selected {
      opacity: 1;
      font-weight: bolder;
    }
  }

  .mantine-Accordion-content {
    a {
      opacity: 1;
      margin-top: 0;
    }
  }

  .mantine-Accordion-content {
    display: block !important;
    color: $grey;

    @include mobile-only {
      & > div {
        margin-bottom: 0.75rem;
      }
    }

    @include from("desktop") {
      margin-bottom: 0.75rem;
    }
  }

  @include from("tablet") {
    display: none;
  }
}

a {
  font-weight: normal;
}

.year-selector::-webkit-scrollbar {
  display: none;
  /* Hide scrollbar for Chrome, Safari and Opera */
}

.year-selector button {
  transition: opacity 0.3s ease;
}

.year-selector {
  background: linear-gradient(
    to right,
    #000000,
    /* Pure black */ #1a1a1a /* Dark grey */
  );
  width: 50%;
  max-width: 600px;
  margin-bottom: 2rem;
  position: sticky;
  bottom: 1.5rem;
  left: 50%;
  height: 50px;
  transform: translateX(-50%);
  overflow-x: scroll;
  font-weight: bolder;
  flex-wrap: nowrap;
  display: flex;
  padding: 0.5rem 1rem;
  gap: 0.5rem;
  border-radius: 30px;
  -ms-overflow-style: none;
  /* Hide scrollbar for IE and Edge */
  scrollbar-width: none;
  align-items: center;
  /* Hide scrollbar for Firefox */
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.2);
  align-items: center;

  @include mobile-only {
    width: calc(100% - 50px - 4.5rem);
    left: 1.5rem;
    transform: translate(0);
  }

  &.footer-visible {
    position: static;
  }

  button {
    background-color: transparent;
    border-color: transparent;
    color: $grey;

    flex-shrink: 0;

    &:first-of-type {
      min-width: 5rem;
    }

    min-width: 3.5rem;
    font-family: inherit;
    cursor: pointer;
    width: max-content;
    text-align: center;
    display: flex;
    justify-content: center;
    font-size: $text_normal;
    align-items: center;
    gap: 0.25rem;

    &:first-of-type {
      min-width: 5rem;
    }

    & > * {
      flex-shrink: 0;
    }

    .dot {
      height: 0.75rem;
      width: 0.75rem;
      background-color: white;
      border-radius: 50%;
      display: inline-block;
      margin-right: 0.25rem;
      transform: translateY(-1px);
    }

    &:hover {
      color: white;
    }

    &.selected {
      color: white;
      font-weight: bold;
    }

    &:first-of-type {
    }
  }
}

.project-slug {
  padding-top: 4rem;

  .project-subtitle {
    font-weight: normal;
    font-size: $text_big;
    line-height: 1.2;
  }

  .cover {
    width: 100%;
    object-fit: cover;
    object-position: center;
    position: relative;

    img {
      width: 100%;
    }
  }

  .description {
    gap: 1.5rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    font-weight: normal;
    font-size: $text_medium;

    @include mobile-only {
      grid-template-columns: 1fr;
      gap: 0;
      padding-top: 0;

      .empty-block {
        display: none;
      }
    }

    * {
      margin-bottom: 0.5em;
    }

    br {
      display: none;
    }
  }

  .images-grid {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    gap: 1.5rem;
    // padding-block: 1.5rem;

    @include tablet-only {
      grid-template-columns: repeat(6, 1fr);
    }

    @include mobile-only {
      grid-template-columns: repeat(4, 1fr);
    }

    .image {
      background-color: #f4f8f6;
      aspect-ratio: 1;
      position: relative;
      transition: 0.1s all;
      cursor: pointer;

      &::after {
        content: "";
        position: absolute;
        inset: 0;
        background-color: rgba(0, 0, 0, 0.05);
      }

      &.bw {
        filter: grayscale(1);
      }

      &.btn {
        border: none;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }

      &:hover {
        filter: grayscale(0);
      }
    }
  }
}

.line {
  display: flex;
  // grid-template-columns: repeat(2, 1fr);
  border-top: 1px solid $black;
  padding-block: 0.75rem;
  gap: 1.5rem;

  &.block {
    display: block;
  }

  @include mobile-only {
    display: block;
  }

  & > * {
    flex: 1;
  }

  p {
    margin: 0;
  }
}

.tab {
  font-weight: normal;

  &.tab-nav-mobile {
    .line {
      padding-block: 1.5rem;
      font-size: $text_medium;

      a {
        outline: none;
      }
    }
  }

  .see-more-btn {
    background-color: transparent;
    border: 0;
    font-size: inherit;
    font-family: inherit;
    opacity: 0.5;
    transition: 0.1s all;
    cursor: pointer;
    color: black;
    flex: 0;

    &:hover {
      opacity: 1;
    }
  }
}

.new-footer {
  background-color: $black-light;
  color: white;
  height: calc(100vh - 4.35rem);
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding: 1.5rem;
  gap: 1.5rem;

  @include mobile-only {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
  }

  .footer-left {
    display: flex;
    align-items: flex-end;
    padding-right: 4rem;

    @include mobile-only {
      flex-direction: column;
      align-items: flex-start;
    }

    .down-mobile {
      padding-top: 1.5rem;

      .legal-nav {
        display: flex;
        gap: 1.5rem;
      }

      @include from("tablet") {
        display: none;
      }
    }

    .logo-container {
      .logo {
        width: 100%;
        fill: white;
        height: auto;
      }

      // padding-bottom: 1.5rem;
      display: flex;
      align-items: flex-end;
      width: 100%;
    }
  }

  .footer-right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .up {
      display: grid;
      grid-template-columns: repeat(2, 1fr);

      a {
        @include link;
        opacity: 1;

        &:hover {
          opacity: 0.4;
        }
      }

      @include mobile-only {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
      }
    }
  }
}

.link {
  @include link;
  opacity: 1;

  &:hover {
    opacity: 0.4;
  }
}

.agency-page {
  .agency-nav {
    display: flex;
    gap: 1rem;
    font-size: 1rem;

    button {
      background-color: transparent;
      border: 0;
      color: $grey;
      font-weight: normal;
      cursor: pointer;

      &.active,
      &:hover {
        color: black;
      }

      &.selected {
        color: black;
        font-weight: bold;
      }
    }
  }

  .img-couverture {
    margin-bottom: 1.5rem;

    .img-cover-container {
      position: relative;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
  }
}

p {
  margin-bottom: 0.75rem;
}

.philosophie-page {
  .title {
    font-size: calc(2rem + 1vw);
    line-height: 1.2;
    margin-top: 1.5rem;
  }

  .philo-content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1.5rem;

    @include until("desktop") {
      grid-template-columns: 1fr;
    }

    .right-content {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 1.5rem;

      @include mobile-only {
        grid-template-columns: 1fr;
      }

      p {
        font-weight: normal;
        font-size: $text_normal;
      }

      h2,
      h3 {
        margin-bottom: 1rem;
      }
    }
  }
}

.accordion {
  .fullwidth {
    .mantine-Accordion-content {
      display: flex !important;
      flex-direction: column;

      .images-grid {
        .btn {
          border: none;
        }
      }
    }
  }

  .mantine-Accordion-item {
    border-bottom: none !important;
    border-top: 1px $black solid !important;

    .custom-chevron {
      height: 9px;
      width: 9px;
      border-radius: 50%;
      background-color: transparent;
      border: 1px $black solid;

      &.invisible {
        opacity: 0;
      }
    }

    .mantine-Accordion-label {
      padding-block: 0.75rem !important;
    }

    &[data-active="true"] {
      .custom-chevron {
        background-color: $black !important;
        transition: 0.2s all;
      }

      .mantine-Accordion-label {
        .line-grid {
          .title {
            font-weight: bold;
          }
        }
      }
    }

    .line-title {
      padding-inline: 0 !important;
      position: relative;

      &:hover {
        background-color: transparent;
      }

      .mantine-Accordion-chevron {
        position: absolute;
        right: 0;
        top: 0.9rem;
      }

      .line-grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        position: relative;
        gap: 1.5rem;

        @include mobile-only {
          display: block;
        }

        p {
          margin: 0;

          &.title {
            // font-size: $text_medium;
          }
        }
      }
    }

    &:not(.fullwidth) {
      .mantine-Accordion-content {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        padding: 0;
        padding-bottom: 1.5rem;
        gap: 1.5rem;

        @media (max-width: 768px) {
          grid-template-columns: 1fr;
        }

        .left-details {
          width: 100%;
          min-height: 1px;

          .image-grid {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 1.5rem;

            @media (max-width: 768px) {
              grid-template-columns: 1fr;

              .img-container {
                grid-column: 1 / -1;
              }

              .spacer-column {
                display: none;
              }
            }

            .img-container {
              position: relative;
              width: 100%;
              min-width: 0;
              max-width: 100%;
            }
          }

          .spacer-column {
            width: 100%;
            min-height: 1px;
            grid-column: 1 / -1;
          }
        }

        .details {
          width: 100%;

          &.one-col {
            font-size: $text_medium;

            > div {
              width: 100%;
            }
          }

          &.two-cols {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 1.5rem;

            @media (max-width: 768px) {
              grid-template-columns: 1fr;

              > div:first-child {
                order: 2; // Move text after image
              }

              .details-image {
                order: 1; // Move image before text
              }
            }

            > div:first-child {
              width: 100%;
            }

            .details-image {
              position: relative;
              width: 100%;
              min-width: 0;
              max-width: 100%;
              height: auto;
            }
          }
        }
      }
    }
  }
}

.documentation {
  margin-top: 8rem;

  h3 {
    font-size: $text_medium;
    margin-bottom: 1.5rem;
    font-weight: normal;
  }
}

.project-drawer-images {
  position: relative;

  .close-btn {
    position: absolute;
    bottom: 1rem;
    right: 1rem;
    border-radius: 50%;
    height: 50px !important;
    width: 50px;
    border: none;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
  }

  .mantine-Drawer-content {
    background-color: #ededed !important;

    .page-nb {
      position: fixed;
      bottom: 1.5rem;
      left: 1.5rem;
      z-index: 2;
      width: unset !important;
      height: unset !important;
    }

    .mantine-Drawer-body {
      height: 100%;
      padding: calc(50px + 2rem) 0 calc(50px + 2rem);
      position: relative;

      .swiper {
        height: 100%;
        width: 100%;
        position: relative;

        * {
          height: 100%;
          // width: 100%;
          margin: auto;
        }
      }

      .swiper-img-container {
        position: relative;
        overflow: hidden;

        img {
          // padding-inline: 1.5rem;
          width: 100%;
          height: 100%;
          object-fit: contain;
          background-color: #ededed !important;
        }
      }
    }
  }
}

.grid-skeletton {
  @include project-grid;
}

.page {
  &.home {
    position: relative;

    .img-container {
      position: relative;
      width: 100%;
      height: 100dvh;

      & > div {
        position: relative;
        width: 100%;
        height: 100%;

        &::after {
          content: "";
          inset: 0;
          position: absolute;
          background-color: rgba(0, 0, 0, 0.25);
        }
      }

      img {
        width: 100%;
        object-fit: cover;
        object-position: center;
        pointer-events: none;
        user-select: none;
        position: relative;
      }
    }

    .actu-text {
      position: absolute;
      right: 1.5rem;
      bottom: 1.5rem;
      width: 50vw;
      padding-right: 0;
      color: white;
      font-size: 1.25rem;

      @include mobile-only {
        left: 1.5rem;
        width: 75vw;
      }

      // @include tablet {}

      // @include desktop {}

      p {
        margin-bottom: 0.75rem;
      }

      .see-more {
        font-size: 1rem;
        @include link;
        opacity: 0.75;
      }
    }
  }
}

.footer-right {
  p {
    margin-bottom: 0.25rem;
  }

  .down {
    @include mobile-only {
      display: none;
    }
  }
}

.bloc-title {
  font-size: $text_medium;
  font-weight: normal;
  margin-bottom: 1.5rem;
}

.burger-btn {
  background-color: $black;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  position: fixed;
  bottom: 1.5rem;
  right: 1.5rem;
  z-index: 2;
  border: 0;

  @include from("tablet") {
    display: none;
  }
}

.mobile-menu {
  z-index: 1001 !important;
  position: relative;

  .mantine-Drawer-content {
    background-color: $black-light;
    // position: fixed;
    // inset: 0;

    .mantine-Drawer-body {
      padding: 1.5rem;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .logo-container {
        svg {
          fill: white;
        }
      }

      .tab {
        color: white;
        margin-bottom: 4rem;

        .line {
          border-color: white;
        }
      }

      .close-btn {
        position: fixed;
        right: 1.5rem;
        bottom: 1.5rem;

        button {
          height: 50px;
          width: 50px;
          background-color: white;
          border-radius: 50%;
          border: none;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
}

.mapboxgl-popup-content {
  padding: 0 !important;
}

.sub-section {
  padding-bottom: 0.75rem;

  &:not(:last-child) {
    padding-bottom: 1.5rem;
  }

  .subsection-title {
    font-weight: bolder;
    margin-bottom: 0.75rem;
    display: block;
  }

  .sub-sub-section {
    br {
      display: none;
    }

    &:not(:last-child) {
      padding-bottom: 0.75rem;
    }

    p {
      &:last-child {
        padding-bottom: 0.75rem;
      }
    }
  }
}

.see-more-section {
  border-top: 1px black solid;

  h3 {
    font-size: $text_medium;
    margin-bottom: 1.5rem;
    font-weight: normal;
  }

  .see-more-projects {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1.5rem;

    @include mobile-only {
      grid-template-columns: 1fr;
    }

    .see-more-link {
      margin-bottom: 1.5rem;
      // font-weight: bold
      color: $grey;
      display: block;
    }

    .project-index {
      @include project-grid;
      grid-template-columns: repeat(2, 1fr);

      .see-more-project {
        position: relative;
        // display: grid;
        // grid-template-columns: repeat(2, 1fr);
      }
    }
  }
}

.documentation {
  .img-grid {
    .mantine-Accordion-content {
      gap: 0 !important;
    }
  }
}

.see-all {
  @include link;
}

.bloc,
.description {
  a {
    @include link;
  }
}

section {
  margin-bottom: 4rem;
}

.themes-accordion {
  .mantine-Accordion-content {
    padding-bottom: 0 !important;
  }
}

.not-found {

  min-height: 60vh !important;
  p, a {
    font-size: 1.2rem;
  }
  a {
    text-decoration: underline;
    text-underline-offset: 4px;
    display: block;
  }

  .ctas {
    display: flex;
    gap: 1rem;
  }
}
