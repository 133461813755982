@import 'variables';

.mapboxgl-popup {
  max-width: unset !important;

  .mapboxgl-popup-close-button {
    display: none;
  }
}

.popup {
  width: 15rem;

  .img-container {
    width: 100%;
    aspect-ratio: 1;
    // height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .popup-content {
    padding: 0.75rem;
    font-family: 'Hermes';

    h3 {
      font-size: $text_normal;
      word-wrap: break-word;
      hyphens: auto;
    }
  }
}

.world-container {
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  // height: 100vh;
  // position: fixed;

  .mapboxgl-map {
    border-radius: 0 !important;
    height: 100% !important;
    width: 100%;
    flex: 1;

    .map {
      height: 100%;
      width: 50%;
    }
  }
}

.mapboxgl-ctrl-attrib-inner {
  display: none;
}