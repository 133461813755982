$grey: #808080;
$black-light: #202020;
$black: #0c0c0c;
$text_big: calc(2rem + 1vw);
$text_medium: 1.25rem;
$text_normal: 1rem;

$font-size-base: 16px;
$font-size-desktop: 14px;
$font-size-mobile: 12px;

$font-size-desktop-percentage: (
  $font-size-desktop / $font-size-base) * 100%;
$font-size-mobile-percentage: (
  $font-size-mobile / $font-size-base) * 100%;

$breakpoints: (
  'mobile': 480px,
  'tablet': 768px,
  'desktop': 1024px,
  'large': 1200px) !default;

// Mixin for min-width queries (mobile-first approach)
@mixin from($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media (min-width: map-get($breakpoints, $breakpoint)) {
      @content;
    }
  }

  @else {
    @warn "Invalid breakpoint: #{$breakpoint}.";
  }
}

// Mixin for max-width queries
@mixin until($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media (max-width: (map-get($breakpoints, $breakpoint) - 1)) {
      @content;
    }
  }

  @else {
    @warn "Invalid breakpoint: #{$breakpoint}.";
  }
}

// Mixin for between queries
@mixin between($from, $until) {
  @if map-has-key($breakpoints, $from) and map-has-key($breakpoints, $until) {
    @media (min-width: map-get($breakpoints, $from)) and (max-width: (map-get($breakpoints, $until) - 1)) {
      @content;
    }
  }

  @else {
    @warn "Invalid breakpoint: #{$from} or #{$until}.";
  }
}

@mixin mobile-only {
  @include until('tablet') {
    @content;
  }
}

@mixin tablet-only {
  @include between('tablet', 'desktop') {
    @content;
  }
}

@mixin desktop-only {
  @include from('desktop') {
    @content;
  }
}

@mixin link {
  transition: 0.1s all;
  opacity: 0.4;

  &:hover {
    text-decoration: none !important;
    opacity: 1;
  }
}