@import './variables.scss';


.header-logo-container {
  @include from('tablet') {
    width: 50% !important;
  }

  width: 100%;
}

.full-logo-header {
  z-index: 1000;
  // background-color: white;
  width: clamp(15rem, 100%, 20rem);
  display: flex;
  position: relative;
  padding-block: 1.5rem;

  @include mobile-only {
    width: 100%;
  }

  &.home {
    background-color: transparent;
    position: unset;

    svg {
      fill: white;
    }
  }

  // svg {
  //   width: 100%;
  // }
}

.home {
  .full-logo-header {
    background-color: transparent;
    position: fixed;
  }

  .logo {
    color: white;
  }
}


.header {
  @include mobile-only {
    display: none !important;
  }

  &.is-white {
    background-color: white;
  }

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  padding-block: 1.5rem;

  &.with-border {
    border-bottom: 1px solid $black-light;
  }


  &.home-header {
    background-color: transparent;

    .logo {
      color: white;
    }

    nav {
      a {
        // color: white !important;
        opacity: 1 !important;

        &:hover {
          opacity: 0.5 !important;
        }
      }
    }

    .full-logo-header {
      background-color: transparent;
    }

    .header-content {

      nav {
        a {
          color: white;
        }
      }
    }
  }

  // box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);

  .header-content {

    display: grid;
    align-items: flex-start;
    grid-template-columns: repeat(2, 1fr);
    font-size: 1rem;
    gap: 1.5rem;

    @include mobile-only {
      grid-template-columns: 1fr;
    }

    .logo {
      font-weight: bold;
      text-transform: uppercase;
    }

    nav {
      display: flex;
      gap: 1.5rem;

      @include mobile-only {
        display: none;
      }

      a {
        // color: $grey;
        @include link;

        &.selected {
          opacity: 1;
        }
      }
    }
  }

  .border-animated {
    will-change: opacity;
    color: $black;
  }
}